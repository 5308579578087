/**
 * @namespace components
 */

import React from 'react';
import classNames from 'classnames';
import ProLink from '../../shared/ProLink/ProLink.js';
import TruncateText from '../../shared/TruncateText/TruncateText.js';
import PropTypes from 'prop-types';
import { miniGazelle } from '../../../helpers/index.js';
import './Breadcrumb.scss';

/**
 * @class Breadcrumb
 *
 * Breadcrumb module with Truncating Text.
 *
 * @memberof components
 *
 * @author Damian Ryan <Damian.Ryan@sony.com>
 *
 * @property {object} data - Primary object to insert data props into component.
 * @property {array} data.structure - Structure of Breadcrumb.
 * @property {string} data.structure.name - Name or title of the page.
 * @property {string} data.structure.link - URL link to said page.
 *
 * @example
 * return {
 *   <Breadcrumb data={data} />
 * }
 */

class Breadcrumb extends React.Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        /**
         * props specific to this module
         **/

        const structure = this.props.data.structure || [];
        const hidden = this.props.hidden || false;

        const breadcrumbMarkup = structure.map((item, i) => {
            return (
                <li key={i}>
                    <ProLink to={item.link}>
                        <TruncateText text={item.name} />
                    </ProLink>
                </li>
            );
        });

        return (
            <nav
                className={classNames({
                    'container-fluid': true,
                    module: true,
                    'module ngp-breadcrumb': !hidden,
                    'ngp-breadcrumb-hide': hidden,
                })}
            >
                <div className="container breadcrumb-wrapper">
                    <div className="col-lg-12 px-sm-0">
                        <ol aria-label={miniGazelle.label('breadcrumbAria', true)} className="breadcrumb">
                            {breadcrumbMarkup}
                        </ol>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Breadcrumb;
